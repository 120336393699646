.App {
	text-align: center;
	background-color: rgb(255, 255, 255);
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.clickable:hover {
	cursor: pointer;
}

.fade-color {
	transition: color 0.25s;
}

.fade-out {
	opacity: 0 !important;
	width: 0 !important;
	height: 0 !important;
	overflow: hidden;
	transition: opacity 0.5s, width 0s, height 0s;
	transition-delay: 2s, 2.5s, 2.5s;
}

table thead tr th:hover {
	cursor: pointer;
	color: #7EA2A1;
	mix-blend-mode: difference;
}

table tbody tr:hover {
	cursor: pointer;
}

.mapboxgl-popup-tip {
	/* border-top-color: #000814 !important; */
	border-top-color: transparent !important;
}

.mapboxgl-popup-content {
	/* background-color: #000814 !important; */
	background-color: transparent !important;
}

.mapboxgl-popup-close-button {
	color: white !important;
}

.hide-scrollbar {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome */
}

#progress-bar, #progress-bar * {
	-webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}